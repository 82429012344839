.home-page-link {
  /* overriding theming inline styles */
  color: inherit!important;

  &:hover {
    text-decoration: none;
  }

  &__box {
    // medium (bootstrap 4)
    @media (min-width: 768px) {
      min-height: 310px;
    }
    // large
    @media (min-width: 992px) {
      min-height: 250px;
    }

    // x large
    @media (min-width: 1199px) {
      min-height: 200px;
    }
  }
}

.home-page-link__box {
  border-radius: 12px;
  box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.2);
  height: 100%;
  &:hover {
    background-color: #F5F5F5;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
