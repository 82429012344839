.header {
  align-items: center;
  background-color: #37424a;
  color: white;
  display: flex;
  /* These are the fonts declared by Bootstrap. We need font definitions for 
  our components from UX! */
  font-family:  'Helvetica Neue', Arial, sans-serif;
  padding: 15px;

  &__logo {
    /* vertical white space fix. (https://tinyurl.com/ydgu2p4y) */
    display: block;
    margin-right: 45px;
    /* position and top rules used to visually center the logo.
    (https://tinyurl.com/y6wbqbt8) */
    position: relative;
    top: 1px;
    max-width: 120px;
    max-height: 38px;
    fill: #fff;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    margin-right: auto;
    padding-right: 10px;
  }

  &__home-icon {
    margin-right: 5px;
  }

  &__home-text {
    display: none;
  }

  &__home-link {
    color: white;
    margin-top: 0;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  @media only screen and (min-width: 767px) {
    &__logo {
      width: 146px;
      height: 46px;
    }

    &__home-text {
      display: inline;
      font-weight: bold;
    }

    &__title {
      font-size: 24px;
    }
  }
}